import BaseModel, { EntityType } from './BaseModel'

import type { Mail as MailInterface } from '~/interfaces/api/Mail.interface'

export default class Mail extends BaseModel implements MailInterface {
  declare id: number
  declare name: string
  declare state: string
  declare status: string
  declare type: string
  declare read: boolean
  declare created_at: Date
  declare read_at: Date
  declare company_id: number
  static entity = EntityType.companyMail

  static fields() {
    return {
      id: this.number(0),
      name: this.string(''),
      state: this.string(''),
      status: this.string(''),
      source: this.string(''),
      type: this.string(''),
      pages: this.number(0),
      created_at: this.attr(''),
      read_at: this.attr(''),
      company_id: this.number(0),
    }
  }
}
