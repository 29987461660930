import BaseModel, { EntityType } from './BaseModel'
import Product from './Product'
import type { Category as CategoryInterface } from '~/interfaces/api/Categories.interface'

export default class Category extends BaseModel implements CategoryInterface {
  declare id: number
  declare resource_type: string
  declare created_at: Date
  declare updated_at: Date
  declare name: string
  declare slug: string
  declare description: string
  declare short_description?: string
  declare is_active: boolean
  declare active_products_count: number

  static entity = EntityType.categories

  static fields() {
    return {
      id: this.number(0),
      resource_type: this.string(''),
      created_at: this.attr(''),
      updated_at: this.attr(''),
      name: this.string(''),
      slug: this.string(''),
      description: this.string(''),
      short_description: this.string(''),
      is_active: this.boolean(false),
      active_products_count: this.number(0),
      products: this.hasMany(Product, 'category_id'),
    }
  }
}
