import type { NitroFetchOptions, NitroFetchRequest } from 'nitropack'

type DefaultResponse<T> = { data: T; meta?: { total: number } }

export const useFetchAuth = async <T = unknown>(
  url: string,
  opts?: NitroFetchOptions<
    NitroFetchRequest,
    // This is pretty ugly but it satisfies nitro types
    | 'delete'
    | 'get'
    | 'head'
    | 'patch'
    | 'post'
    | 'put'
    | 'connect'
    | 'options'
    | 'trace'
  >,
): Promise<DefaultResponse<T>> => {
  const config = useRuntimeConfig()
  const { data: session } = useSessionState()
  let signOut: any = null

  if (!import.meta.server) {
    const sess = useSession()
    signOut = sess.signOut
  }
  const headers: HeadersInit = {
    ...(opts?.headers || {}),
    ...(session.value.user.accessToken && {
      Authorization: `Bearer ${session.value.user.accessToken}`,
    }),
  }

  let apiBase = ''
  if (import.meta.server) apiBase = config.internalApiUrl
  else apiBase = config.public.baseUrl

  try {
    const response = await $fetch<DefaultResponse<T>>(`${apiBase}${url}`, {
      ...opts,
      headers,
    })
    return response
  } catch (error) {
    if (error.status === 401) {
      if (!import.meta.server) signOut()
    } else if (error.status === 403) navigateTo('/403')
    console.error('error', error.status)
    throw createError({
      data: (error as FetchError).response,
    })
  }
}
