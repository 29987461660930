import BaseModel, { EntityType } from './BaseModel'
import Category from './Category'
import Experiment from './Experiment'
import ProductVersion from './ProductVersion'
import TaskRecipe from './TaskRecipe'

import type { Product as ProductInterface } from '~/interfaces/api/Products.interface'

export default class Product extends BaseModel implements ProductInterface {
  static entity = EntityType.products

  declare id: number
  declare resource_type: string
  declare created_at: Date
  declare updated_at: Date
  declare name: string
  declare slug: string
  declare description: string
  declare short_description: string
  declare is_active: boolean
  declare is_canonical: boolean
  declare order_type: string
  declare price_cents: number
  // Not sure on this one due to category_id
  declare parent_id?: number | string
  declare category_id: string
  declare category_name: string
  declare status: string
  declare default_product_version_id: string
  declare default_product_version_slug?: string
  declare default_lander_slug?: string
  declare aliases_list: string

  static fields() {
    return {
      id: this.number(0),
      resource_type: this.string(''),
      created_at: this.string(''),
      updated_at: this.string(''),
      name: this.string(''),
      slug: this.string(''),
      description: this.string(''),
      short_description: this.string(''),
      is_active: this.boolean(false),
      product_versions_count: this.attr(''),
      is_canonical: this.boolean(false),
      type: this.attr(''),
      price: this.number(0),
      parent_id: this.attr(''),
      category_id: this.attr(''),
      category_name: this.attr(''),
      category: this.belongsTo(Category, 'category_id'),
      order_type: this.string(''),
      price_cents: this.number(0),
      status: this.string(''),
      default_product_version_id: this.string(''),
      default_product_version_slug: this.string(''),
      default_butter_page: this.attr(''),
      experiments: this.hasMany(Experiment, 'product_id'),
      product_versions: this.hasMany(ProductVersion, 'product_id'),
      task_recipe: this.attr(''),
      aliases_list: this.attr(''),
    }
  }

  get url_for_order_type() {
    switch (this.order_type) {
      case 'Document': {
        return 'documents'
      }
      case 'BusinessService': {
        return 'orders'
      }
      case 'PassportService': {
        return 'passports'
      }
    }
  }

  // id!: number
  // resource_type!: string
  // created_at!: Date
  // updated_at!: Date
  // name!: string
  // slug!: string
  // description!: string
  // short_description!: string
  // is_active!: boolean
  // product_versions_count!: number
  // is_canonical!: boolean
  // price!: number
  // parent_id?: any
  // category_id!: string
  // category_name!: string
  // locale_id!: string
  // locale_name!: string
  // order_type!: string
  // price_cents!: number
  // status!: string
  // default_product_version_id!: string
  // default_product_version_slug?: string
  // default_lander_slug?: string
  // experiments?: []
}
