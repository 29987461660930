// import { store } from '~/store'
import VuexORM from '@vuex-orm/core'
import database from '~/database/database'
import { createStore } from 'vuex'

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(
    createStore({
      state: {},
      plugins: [VuexORM.install(database)],
    }),
  )
})
