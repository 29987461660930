import VuexORM from "@vuex-orm/core"
import Category from "~/models/Category"
import Product from "~/models/Product"
import Payload from "~/models/Payload"
import Form from "~/models/Form"
import Experiment from "~/models/Experiment"
import ProductVersion from "~/models/ProductVersion"
import User from "~/models/User"
import Charge from "~/models/Charge"
import Document from "~/models/Document"
import Company from "~/models/Company"
import CompanyMail from "~/models/CompanyMail"
import Session from "~/models/Session"
import Orders from "~/models/Order"
import Refunds from "~/models/Refund"
import Subscriptions from "~/models/Subscription"
import Cards from "~/models/Card"
import Plans from "~/models/Plan"
import AdminUsers from "~/models/AdminUser"
import CurrentUser from "~/models/CurrentUser"
import StripeAlerts from "~/models/StripeAlerts"
import Chargeback from "~/models/Chargeback"
import StateInfo from "~/models/StateInfo"
import Stats from "~/models/Stats"
import DayStats from "~/models/DayStats"
import Note from "~/models/Note"
import CompanyFiles from "~/models/CompanyFiles"
import RecurlyError from "~/models/RecurlyError"
import ForeignEntity from "~/models/ForeignEntity"
import ComplianceGuard from "~/models/ComplianceGuard"
import ComplianceItem from "~/models/ComplianceItem"
import ComplianceAmendment from "~/models/ComplianceAmendment"
import ComplianceOrder from "~/models/ComplianceOrder"
import Lander from "~/models/Lander"
import Task from "~/models/Task"
import Notification from "~/models/Notification"
import Label from "~/models/Label"
import Team from "~/models/Team"
import TaskRecipe from "~/models/TaskRecipe"
import OrderStatusTemplate from "~/models/OrderStatusTemplate"
import TasksCounter from "~/models/TasksCounter"
import BOIRSubmission from "~/models/BOIRSubmission"

const database = new VuexORM.Database()

database.register(BOIRSubmission)
database.register(Category)
database.register(Product)
database.register(Payload)
database.register(Form)
database.register(Experiment)
database.register(ProductVersion)
database.register(User)
database.register(Charge)
database.register(Document)
database.register(Company)
database.register(CompanyMail)
database.register(Session)
database.register(Orders)
database.register(Refunds)
database.register(Subscriptions)
database.register(Cards)
database.register(Plans)
database.register(AdminUsers)
database.register(CurrentUser)
database.register(StripeAlerts)
database.register(Chargeback)
database.register(StateInfo)
database.register(Stats)
database.register(DayStats)
database.register(Note)
database.register(CompanyFiles)
database.register(RecurlyError)
database.register(ForeignEntity)
database.register(ComplianceGuard)
database.register(ComplianceItem)
database.register(ComplianceAmendment)
database.register(ComplianceOrder)
database.register(Lander)
database.register(Task)
database.register(Notification)
database.register(Label)
database.register(Team)
database.register(TaskRecipe)
database.register(OrderStatusTemplate)
database.register(TasksCounter)

export default database
